<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add User</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="800">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >User Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--First Name-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_nameFirst"
                    label="First Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Last Name-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_nameLast"
                    label="Last Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Email-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Password-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Audit Icon Color-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_color"
                    label="Audit Icon Color"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Access-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="valueListsStore.yesNo"
                    label="Site Access"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_access"
                  />
                </v-col>
              </v-row>

              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>Navigation</v-tab>
                <v-tab>System Functions</v-tab>
                <v-tab>Reports / Process</v-tab>

                <!--Navigation-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="12">
                            <v-radio-group class="py-0 my-0">
                              <v-checkbox
                                v-for="(check, index) in navigationStore.navigation"
                                v-model="j_navSelected"
                                :label="check.d_description"
                                :value="check.id"
                                class="pr-4 my-0"
                                :key="index"
                                multiple
                                color="primary"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Restrictions-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--Admin-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Admin Section Access"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_admin"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Specialist List-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Show In Specialist List"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_counselor"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Auto BCC On Email-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Auto BCC On Emails"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_bcc"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Care Plan Editing-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Care Plan Editing"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_carePlanEdit"
                            />
                          </v-col>

                          <!--Care Plan Status-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Care Plan Option Status Change"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_carePlanStatus"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Attendance Editing-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Attendance Editing"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_attendanceEdit"
                            />
                          </v-col>

                          <!--Attendance Status-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Attendance Status Change"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_attendanceStatus"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Payment Edit-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Payment Editing"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_paymentEdit"
                            />
                          </v-col>

                          <!--Payment Status-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Payment Status Change"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_paymentStatus"
                            />
                          </v-col>

                          <!--Payment Duplicate-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Payment Corrections"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_paymentDuplicate"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Teacher Review Editing-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Teacher Review Editing"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_teacherReviewEdit"
                            />
                          </v-col>

                          <!--Teacher Review Status-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Teacher Review Status Change"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_teacherReviewStatus"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Teacher Payment Edit-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Teacher Payment Editing"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_teacherPaymentEdit"
                            />
                          </v-col>

                          <!--Teacher Payment Status-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Teacher Payment Status Change"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_teacherPaymentStatus"
                            />
                          </v-col>

                          <!--Teacher Payment Duplicate-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Teacher Payment Corrections"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_teacherPaymentDuplicate"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Delete-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="Delete Records"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_delete"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--View Private Records-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="View Private Records"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="f_viewPrivateRecords"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Reports-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="12">
                            <v-radio-group class="py-0 my-0">
                              <v-checkbox
                                v-for="(check, index) in reportsStore.reportsValueList"
                                v-model="j_reportsSelected"
                                :label="check.d_name"
                                :value="check.id"
                                class="pr-4 my-0"
                                :key="index"
                                multiple
                                color="primary"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "usersStore/getEntryField",
  mutationType: "usersStore/updateEntryField"
});

export default {
  name: "UserEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      navigationStore: state => state.navigationStore,
      valueListsStore: state => state.valueListsStore,
      reportsStore: state => state.reportsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "d_nameFirst",
      "d_nameLast",
      "email",
      "password",
      "d_color",
      "f_bcc",
      "f_admin",
      "f_counselor",
      "f_carePlanEdit",
      "f_carePlanStatus",
      "f_attendanceEdit",
      "f_attendanceStatus",
      "f_paymentEdit",
      "f_paymentStatus",
      "f_paymentDuplicate",
      "f_teacherReviewEdit",
      "f_teacherReviewStatus",
      "f_teacherPaymentEdit",
      "f_teacherPaymentStatus",
      "f_teacherPaymentDuplicate",
      "f_viewPrivateRecords",
      "f_delete",
      "f_access",
      "j_navSelected",
      "j_reportsSelected"
    ])
  },
  data() {
    return {
      navigation: [],
      tab: 0
    };
  },
  methods: {
    async newEntry() {
      await this.$store.dispatch("navigationStore/active");
      const nav = this.$store.getters["navigationStore/getNavigation"];
      const newArray = [];
      for (let i = 0; i < nav.length; i++) {
        newArray.push(nav[i].id);
      }
      await this.$store.dispatch("reportsStore/valueList");
      /*const reports = this.$store.getters["reportsStore/getReportsValueList"];
      const reportList = [];
      for (let i = 0; i < reports.length; i++) {
        reportList.push(reports[i].id);
      }*/
      this.valid = true;
      this.mode = 1;
      this.id = "";
      this.d_nameFirst = "";
      this.d_nameLast = "";
      this.email = "";
      this.password = "";
      this.f_bcc = "";
      this.f_counselor = "";
      this.f_admin = "";
      this.f_attendanceEdit = 0;
      this.f_attendanceStatus = 0;
      this.f_paymentEdit = 0;
      this.f_paymentStatus = 0;
      this.f_teacherReviewEdit = 0;
      this.f_teacherReviewStatus = 0;
      this.f_teacherPaymentEdit = 0;
      this.f_teacherPaymentStatus = 0;
      this.f_teacherPaymentDuplicate = 0;
      this.f_paymentDuplicate = 0;
      this.f_delete = 0;
      this.f_access = true;
      this.show = true;
      this.j_navSelected = newArray;
      this.j_reportsSelected = [];
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["usersStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("usersStore/create", entry);
        } else {
          /* Edit */
          await this.$store.dispatch("usersStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
